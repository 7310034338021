@font-face {
font-family: '__literata_ae3052';
src: url(/_next/static/media/6aec8957faac488c.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__literata_ae3052';
src: url(/_next/static/media/a19dfa41d9070918.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__literata_ae3052';
src: url(/_next/static/media/a00306919c5a7099.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__literata_ae3052';
src: url(/_next/static/media/6e85710ecdc84f02.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__literata_ae3052';
src: url(/_next/static/media/aa4baeaaba5e6473.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__literata_ae3052';
src: url(/_next/static/media/d06fb562871a22a8.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__literata_Fallback_ae3052';src: local("Arial");ascent-override: 110.29%;descent-override: 28.86%;line-gap-override: 0.00%;size-adjust: 106.72%
}.__className_ae3052 {font-family: '__literata_ae3052', '__literata_Fallback_ae3052'
}

@font-face {
font-family: '__zodiak_0c0f6f';
src: url(/_next/static/media/74d2db6567ecd245.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__zodiak_0c0f6f';
src: url(/_next/static/media/e92608633119e348.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__zodiak_0c0f6f';
src: url(/_next/static/media/74d2db6567ecd245.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__zodiak_0c0f6f';
src: url(/_next/static/media/6e94043502755c55.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__zodiak_0c0f6f';
src: url(/_next/static/media/2b012d20a3c1a95c.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__zodiak_0c0f6f';
src: url(/_next/static/media/640ce291c39299a5.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__zodiak_Fallback_0c0f6f';src: local("Arial");ascent-override: 88.19%;descent-override: 23.16%;line-gap-override: 8.02%;size-adjust: 112.26%
}.__className_0c0f6f {font-family: '__zodiak_0c0f6f', '__zodiak_Fallback_0c0f6f'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_999900';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_999900';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_999900';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_999900';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_999900';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_999900';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_999900';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_999900';src: local("Arial");ascent-override: 90.00%;descent-override: 22.43%;line-gap-override: 0.00%;size-adjust: 107.64%
}.__className_999900 {font-family: '__Inter_999900', '__Inter_Fallback_999900';font-style: normal
}

/* vietnamese */
@font-face {
  font-family: '__Anek_Latin_c94780';
  font-style: normal;
  font-weight: 100 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/2a8c65eae3dc9f84.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Anek_Latin_c94780';
  font-style: normal;
  font-weight: 100 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/b20becce049e2fb1.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Anek_Latin_c94780';
  font-style: normal;
  font-weight: 100 800;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/0dbe5a42bf6aec9f.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Anek_Latin_Fallback_c94780';src: local("Arial");ascent-override: 94.58%;descent-override: 21.02%;line-gap-override: 0.00%;size-adjust: 95.16%
}.__className_c94780 {font-family: '__Anek_Latin_c94780', '__Anek_Latin_Fallback_c94780';font-style: normal
}

@font-face {
font-family: '__monaSans_0b8b2a';
src: url(/_next/static/media/d69a967dfca6bd55.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__monaSans_0b8b2a';
src: url(/_next/static/media/5723f4a8f1b2fecb.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__monaSans_0b8b2a';
src: url(/_next/static/media/d69a967dfca6bd55.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__monaSans_0b8b2a';
src: url(/_next/static/media/1167ea39f78e273a.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__monaSans_0b8b2a';
src: url(/_next/static/media/8ef9f4bfb38773fa.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__monaSans_0b8b2a';
src: url(/_next/static/media/ac63a321caf55d9f.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__monaSans_Fallback_0b8b2a';src: local("Arial");ascent-override: 76.46%;descent-override: 15.96%;line-gap-override: 0.00%;size-adjust: 104.63%
}.__className_0b8b2a {font-family: '__monaSans_0b8b2a', '__monaSans_Fallback_0b8b2a'
}

@font-face {
font-family: '__pilcrowRounded_db5b09';
src: url(/_next/static/media/0f8732ca9a7dc636.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__pilcrowRounded_db5b09';
src: url(/_next/static/media/0f8732ca9a7dc636.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__pilcrowRounded_db5b09';
src: url(/_next/static/media/dfc435796becc8b9.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__pilcrowRounded_db5b09';
src: url(/_next/static/media/3598f4809aefed6d.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__pilcrowRounded_Fallback_db5b09';src: local("Arial");ascent-override: 100.23%;descent-override: 25.06%;line-gap-override: 9.11%;size-adjust: 87.80%
}.__className_db5b09 {font-family: '__pilcrowRounded_db5b09', '__pilcrowRounded_Fallback_db5b09'
}

@font-face {
font-family: '__satoshi_4df5d0';
src: url(/_next/static/media/b1dca2a5d44fc7a4.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__satoshi_4df5d0';
src: url(/_next/static/media/3eb4bb530f30483c.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__satoshi_4df5d0';
src: url(/_next/static/media/22539d17f3707926.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__satoshi_4df5d0';
src: url(/_next/static/media/12084922609e6532.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__satoshi_4df5d0';
src: url(/_next/static/media/e51fcc5397d024ec.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__satoshi_4df5d0';
src: url(/_next/static/media/c6d20a6ba91d97e6.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__satoshi_Fallback_4df5d0';src: local("Arial");ascent-override: 102.23%;descent-override: 24.29%;line-gap-override: 10.12%;size-adjust: 98.80%
}.__className_4df5d0 {font-family: '__satoshi_4df5d0', '__satoshi_Fallback_4df5d0'
}

@font-face {
font-family: '__unbounded_f09b79';
src: url(/_next/static/media/1699dcd8f3fd601c.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__unbounded_f09b79';
src: url(/_next/static/media/f41032bd1339b1a3.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__unbounded_f09b79';
src: url(/_next/static/media/ab5b2515d2fe0618.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__unbounded_f09b79';
src: url(/_next/static/media/759404823e6f3343.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__unbounded_Fallback_f09b79';src: local("Arial");ascent-override: 73.13%;descent-override: 18.01%;line-gap-override: 0.00%;size-adjust: 136.07%
}.__className_f09b79 {font-family: '__unbounded_f09b79', '__unbounded_Fallback_f09b79'
}

@font-face {
font-family: '__bitter_661b55';
src: url(/_next/static/media/e817b6624904dfed.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__bitter_661b55';
src: url(/_next/static/media/542dd5f1deb33e41.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__bitter_661b55';
src: url(/_next/static/media/38521baa82495d4b.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__bitter_661b55';
src: url(/_next/static/media/0eb70b15f97a82aa.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__bitter_661b55';
src: url(/_next/static/media/213fc47771a6d035.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__bitter_661b55';
src: url(/_next/static/media/d08c24a5f506aa20.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__bitter_661b55';
src: url(/_next/static/media/5fadf69f5d39beb6.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__bitter_661b55';
src: url(/_next/static/media/fcda126b9c125ed9.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__bitter_661b55';
src: url(/_next/static/media/325da87639c99ec2.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__bitter_Fallback_661b55';src: local("Arial");ascent-override: 89.15%;descent-override: 25.27%;line-gap-override: 0.00%;size-adjust: 104.88%
}.__className_661b55 {font-family: '__bitter_661b55', '__bitter_Fallback_661b55'
}

@font-face {
font-family: '__vollkorn_36650d';
src: url(/_next/static/media/a4aae8f4387e8c1c.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__vollkorn_36650d';
src: url(/_next/static/media/e5426ecac9c0e6dd.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__vollkorn_36650d';
src: url(/_next/static/media/55780521b57f51bc.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__vollkorn_36650d';
src: url(/_next/static/media/8a42f36925227f18.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__vollkorn_36650d';
src: url(/_next/static/media/b5cedcf1f00c188e.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__vollkorn_36650d';
src: url(/_next/static/media/6f24188f0317c9df.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__vollkorn_36650d';
src: url(/_next/static/media/29d9c0f37155440b.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__vollkorn_36650d';
src: url(/_next/static/media/f992393f760a923d.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__vollkorn_36650d';
src: url(/_next/static/media/846d2470a2731e06.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__vollkorn_Fallback_36650d';src: local("Arial");ascent-override: 96.22%;descent-override: 44.57%;line-gap-override: 0.00%;size-adjust: 98.94%
}.__className_36650d {font-family: '__vollkorn_36650d', '__vollkorn_Fallback_36650d'
}

@font-face {
font-family: '__antonio_5400b1';
src: url(/_next/static/media/8d70d912ca2169c6.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__antonio_5400b1';
src: url(/_next/static/media/f78cd4600d104bb4.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__antonio_5400b1';
src: url(/_next/static/media/11e034e46ad1622d.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__antonio_5400b1';
src: url(/_next/static/media/06141a0e4cc6dd81.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__antonio_Fallback_5400b1';src: local("Arial");ascent-override: 137.73%;descent-override: 16.60%;line-gap-override: 0.00%;size-adjust: 83.85%
}.__className_5400b1 {font-family: '__antonio_5400b1', '__antonio_Fallback_5400b1'
}

@font-face {
font-family: '__splineSans_535ac9';
src: url(/_next/static/media/0658f079a6fc4367.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__splineSans_535ac9';
src: url(/_next/static/media/23c1fc4b37c963ef.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__splineSans_535ac9';
src: url(/_next/static/media/794b132a3596fd47.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__splineSans_535ac9';
src: url(/_next/static/media/3029367def592308.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__splineSans_Fallback_535ac9';src: local("Arial");ascent-override: 94.11%;descent-override: 23.10%;line-gap-override: 0.00%;size-adjust: 102.38%
}.__className_535ac9 {font-family: '__splineSans_535ac9', '__splineSans_Fallback_535ac9'
}

@font-face {
font-family: '__supreme_f03829';
src: url(/_next/static/media/5f4b1f59818be53a.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__supreme_f03829';
src: url(/_next/static/media/42057afa6698c078.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__supreme_f03829';
src: url(/_next/static/media/38d9a74f03c26f07.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__supreme_f03829';
src: url(/_next/static/media/72a50079ca6c1026.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__supreme_f03829';
src: url(/_next/static/media/c199d3554358173f.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__supreme_Fallback_f03829';src: local("Arial");ascent-override: 98.07%;descent-override: 28.02%;line-gap-override: 9.01%;size-adjust: 99.93%
}.__className_f03829 {font-family: '__supreme_f03829', '__supreme_Fallback_f03829'
}

@font-face {
font-family: '__alpino_a1029f';
src: url(/_next/static/media/6e17ca0bb7b9633a.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__alpino_a1029f';
src: url(/_next/static/media/76123524a72532e3.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__alpino_a1029f';
src: url(/_next/static/media/3d9ad20469563e7b.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__alpino_Fallback_a1029f';src: local("Arial");ascent-override: 109.05%;descent-override: 35.18%;line-gap-override: 9.38%;size-adjust: 85.28%
}.__className_a1029f {font-family: '__alpino_a1029f', '__alpino_Fallback_a1029f'
}

@font-face {
font-family: '__bevellier_0f6ed3';
src: url(/_next/static/media/bb9dd9709981b40f.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__bevellier_0f6ed3';
src: url(/_next/static/media/cbf4a1cf9a21971e.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__bevellier_0f6ed3';
src: url(/_next/static/media/c6b88801b3a797a4.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__bevellier_0f6ed3';
src: url(/_next/static/media/c6ce25746b10c040.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__bevellier_0f6ed3';
src: url(/_next/static/media/fc8d036b60e9445a.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__bevellier_0f6ed3';
src: url(/_next/static/media/adcfbb2b9432f680.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__bevellier_0f6ed3';
src: url(/_next/static/media/2deb9e0ce2123c6c.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__bevellier_0f6ed3';
src: url(/_next/static/media/3eb0c2dfdcdef85e.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__bevellier_Fallback_0f6ed3';src: local("Arial");ascent-override: 122.23%;descent-override: 25.46%;line-gap-override: 12.73%;size-adjust: 78.54%
}.__className_0f6ed3 {font-family: '__bevellier_0f6ed3', '__bevellier_Fallback_0f6ed3'
}

@font-face {
font-family: '__plusJakartaSans_fc9e67';
src: url(/_next/static/media/0a5276aa2db23edb.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__plusJakartaSans_fc9e67';
src: url(/_next/static/media/b2ed18763d2bc6a5.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__plusJakartaSans_fc9e67';
src: url(/_next/static/media/57f2253455690b2b.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__plusJakartaSans_fc9e67';
src: url(/_next/static/media/aaeb7d7362610e5d.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__plusJakartaSans_fc9e67';
src: url(/_next/static/media/7171e0e9660a403a.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__plusJakartaSans_fc9e67';
src: url(/_next/static/media/a4f2b048764be26a.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__plusJakartaSans_fc9e67';
src: url(/_next/static/media/0f31b2d65f17c7a8.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__plusJakartaSans_fc9e67';
src: url(/_next/static/media/f4e177af0a610145.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__plusJakartaSans_fc9e67';
src: url(/_next/static/media/8b97cc7409192c06.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__plusJakartaSans_Fallback_fc9e67';src: local("Arial");ascent-override: 122.93%;descent-override: 34.04%;line-gap-override: 11.35%;size-adjust: 105.75%
}.__className_fc9e67 {font-family: '__plusJakartaSans_fc9e67', '__plusJakartaSans_Fallback_fc9e67'
}

@font-face {
font-family: '__teko_8ed8da';
src: url(/_next/static/media/a561962348344c4e.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__teko_8ed8da';
src: url(/_next/static/media/0cc4032040e6685e.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__teko_8ed8da';
src: url(/_next/static/media/b3c16cfded86ac4f.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__teko_8ed8da';
src: url(/_next/static/media/4a0b30fddcce3a1a.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__teko_Fallback_8ed8da';src: local("Arial");ascent-override: 131.45%;descent-override: 45.33%;line-gap-override: 12.09%;size-adjust: 66.18%
}.__className_8ed8da {font-family: '__teko_8ed8da', '__teko_Fallback_8ed8da'
}

@font-face {
font-family: '__gambetta_e6e1db';
src: url(/_next/static/media/9074a5526c3b9df0.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gambetta_e6e1db';
src: url(/_next/static/media/5cb25c91aa33ede7.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__gambetta_e6e1db';
src: url(/_next/static/media/0a6b2be884cd567e.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__gambetta_e6e1db';
src: url(/_next/static/media/3877b516ddf7a0a0.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__gambetta_e6e1db';
src: url(/_next/static/media/0f501b90d8b0ec6a.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__gambetta_e6e1db';
src: url(/_next/static/media/df6bc9a29880c45a.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__gambetta_e6e1db';
src: url(/_next/static/media/830f716912647896.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__gambetta_e6e1db';
src: url(/_next/static/media/481058ddfaec860f.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__gambetta_Fallback_e6e1db';src: local("Arial");ascent-override: 93.95%;descent-override: 29.30%;line-gap-override: 8.08%;size-adjust: 98.99%
}.__className_e6e1db {font-family: '__gambetta_e6e1db', '__gambetta_Fallback_e6e1db'
}

